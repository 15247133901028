import React ,{useEffect,useState} from 'react';
import { axiosInstance } from "../../axiosInstance";

const VolunteerReport = () => {
    const getVolunteers = () => {
      return axiosInstance.get(`/volunteers`);
    };

    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchVolunteers = async () => {
        try {
            const response = await getVolunteers();
            setData(response.data);
            console.log(response.data);
        } catch (error) {
            console.error("Error fetching volunteers data:", error);
        }
        };
        fetchVolunteers();
    }, []);

    
    return (
        <>
        <section className="reports-banner">
            <div className="container mt-5 mb-5">
                <div className="row bg-light font-weight-bold border p-2 d-none d-md-flex">
                    <div className="col-md-1">First Name</div>
                    <div className="col-md-1">Last Name</div>
                    <div className="col-md-2">Contact</div>
                    <div className="col-md-2">Email</div>
                    <div className="col-md-1">Country</div>
                    <div className="col-md-1">State</div>
                    <div className="col-md-1">City</div>
                    <div className="col-md-1">Pincode</div>
                    <div className="col-md-2">Address</div>
                </div>
                {data.map((item) => (
                <div className="row border p-2">
                    <div className="col-12 col-md-1 text-wrap-column">
                    <strong className='laptop-display-none'>First Name: </strong>{item.first_name}
                    </div>
                    <div className="col-12 col-md-1 text-wrap-column">
                    <strong className='laptop-display-none'>Last Name: </strong>{item.last_name}
                    </div>
                    <div className="col-12 col-md-2 text-wrap-column">
                        <strong className='laptop-display-none'>Contact: </strong>{item.country_code}-{item.contact}
                    </div>
                    <div className="col-12 col-md-2 text-wrap-column">
                        <strong className='laptop-display-none'>Email:</strong> {item.email}
                    </div>
                    <div className="col-12 col-md-1 text-wrap-column">
                        <strong className='laptop-display-none'>Country: </strong>{item.country}
                    </div>
                    <div className="col-12 col-md-1 text-wrap-column">
                        <strong className='laptop-display-none'>State:</strong> {item.state}
                    </div>
                    <div className="col-12 col-md-1 text-wrap-column">
                        <strong className='laptop-display-none'>City:</strong> {item.city}
                    </div>
                    <div className="col-12 col-md-1 text-wrap-column">
                        <strong className='laptop-display-none'>Pincode:</strong> {item.pincode}
                    </div>
                    <div className="col-12 col-md-2 text-wrap-column">
                        <strong className='laptop-display-none'>Address:</strong> {item.address}
                    </div>
                </div>
                ))}
            </div>
        </section>
        </>
    )
}

export default VolunteerReport;
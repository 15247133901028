import React from 'react';
import volunteerQrCode from "../../assets/img/volunteer-qr-code.png";
import pujariQrCode from "../../assets/img/pujari-qr-code.png";
import templeRegistrationQrCode from "../../assets/img/register-temple-qr-code.png";
import poojaShopsQrCode from "../../assets/img/puja-shops-qr-code.png";
import donationQrCode from "../../assets/img/donations-qr-code.png";

const MahasangamYatra = () => {
    return (
        <>
            <section
                className="bsb-hero-1 px-3 banner-gradient mahasangam-yatra-banner"
            >
            </section>
            <div className="mahasangam-yatra-section-container">
                <div className="row qr-code-desc p-5 align-items-center" data-aos="fade-up">
                    <div className='text-start col-md-8 col-12'> 
                        <h1 className='gradient-text display-3 text-white fw-bold mb-2'>Be A Part Of This Divine Journey!</h1> 
                        <p className='fs-3 lead text-white mb-4 lh-sm'>Scan the QR code now to register and join as a <span className='fw-bold fst-italic text-decoration-underline'>Volunteer</span> and contribute to a spiritually uplifting experience. Your dedication can make a difference!
                        </p> 
                    </div>
                    <div className='col-md-4 col-12 text-end'>
                        <img
                        className='col-12 col-md-10 shadow p-3 mb-5 bg-white rounded'
                        src={volunteerQrCode}
                        alt="Volunteer QR Code"
                        />
                    </div>
                </div>

                <div className="row qr-code-desc p-5 align-items-center" data-aos="fade-up">
                    <div className='text-start col-12 col-md-8'> 
                        <h1 className='gradient-text display-3 text-white fw-bold mb-2'>Step Into A Sacred Role!</h1> 
                        <p className='fs-3 lead text-white mb-4 lh-sm'>Scan the QR code now to register and join as a <span className='fw-bold fst-italic text-decoration-underline'>Pujari</span> and be part of this divine service. Embrace the opportunity to connect with the spiritual essence and serve the divine.</p>
                    </div> 
                    <div className='text-end col-12 col-md-4'> 
                        <img
                        src={pujariQrCode}
                        alt="Pujari QR Code"
                        className='col-12 col-md-10 shadow p-3 mb-5 bg-white rounded'
                        />
                    </div>
                </div>

                <div className="row qr-code-desc p-5 align-items-center" data-aos="fade-up">
                    <div className='text-start col-12 col-md-8'> 
                        <h1 className='gradient-text display-3 text-white fw-bold mb-2'>Connect Your Local Temples To A Divine Network!</h1> 
                        <p className='fs-3 lead text-white mb-4 lh-sm'>Scan the QR code now to <span className='fw-bold fst-italic text-decoration-underline'>Register Temples</span> in your area
                        and help preserve our spiritual heritage. Let’s unite for a greater cause!</p> 
                       
                    </div> 
                    <div className='text-end col-12 col-md-4'> 
                        <img
                        src={templeRegistrationQrCode}
                        alt="Temple Registration QR Code"
                        className='col-12 col-md-10 shadow p-3 mb-5 bg-white rounded'
                        />
                    </div>
                </div>

                <div className="row qr-code-desc p-5 align-items-center" data-aos="fade-up">
                    <div className='text-start col-12 col-md-8'> 
                        <h1 className='gradient-text display-3 text-white fw-bold mb-2'>Expand Your Puja Shop's Reach!</h1> 
                        <p className='fs-3 lead text-white mb-4 lh-sm'>Scan the QR code now to register your <span className='fw-bold fst-italic text-decoration-underline'>Puja Shop</span> and connect with a wider community of devotees. Let your sacred offerings find the right audience!</p>   
                        
                    </div> 
                    <div className='text-end col-12 col-md-4'> 
                        <img
                        src={poojaShopsQrCode}
                        alt="Pooja Shops QR Code"
                        className='col-12 col-md-10 shadow p-3 mb-5 bg-white rounded'
                        />
                    </div>
                </div>

                <div className="row qr-code-desc p-5 align-items-center" data-aos="fade-up">
                    <div className='text-start col-12 col-md-8'> 
                        <h1 className='gradient-text display-3 text-white fw-bold mb-2'>Expand Your Impact Through Generosity!</h1> 
                        <p className='fs-3 lead text-white mb-4 lh-sm'>Scan the QR code now to register for <span className='fw-bold fst-italic text-decoration-underline'>Donations</span> and connect with a compassionate community of donors.
                         Let your noble cause reach those who truly care!</p> 
                    </div> 
                    <div className='text-end col-12 col-md-4'> 
                        <img
                        src={donationQrCode}
                        alt="Donation QR Code"
                        className='col-12 col-md-10 shadow p-3 mb-5 bg-white rounded'
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default MahasangamYatra;
